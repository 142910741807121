<template>
  <vs-card>
    <div slot="header" class="w-full items-center flex">
        <h4 class="capitalize">
      {{ this.component.module }}
        </h4>
      <vs-button
        class="ml-auto"
        icon="close"
        size="small"
        @click="$emit('removeComponent', index)"
      ></vs-button>
    </div>
    <div v-show="!isDragging">
      <vue-ckeditor @input="onTextChange($event)" :value="JSONData[index].html" :config="config" />
    </div>
  </vs-card>
</template>

<script>
import VueCkeditor from "vue-ckeditor2";

export default {
  components: {
    VueCkeditor,
  },
  data() {
    return {
      config: {
        toolbar: [
          {
            name: "clipboard",
            items: ["Undo", "Redo"],
          },
          { name: "styles", items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
          {
            name: "basicstyles",
            items: ["Bold", "Italic", "Strike"],
          },
          {
            name: "pagragraph",
            items: ["NumberedList", "BulletedList", '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',],
          },
          { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
          { name: 'document', items: [ 'Source', ] },
          { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
        ],
        versionCheck: false,
        height: 300,
      },
    };
  },
  props: ["component", "index", "JSONData", "isDragging"],
  methods: {
    onTextChange(value){
      this.$emit('handleTextOnchange', value, this.index)
    }
  },
};
</script>
